import { all, call, spawn } from 'redux-saga/effects';
import { routinePromiseWatcherSaga } from 'redux-saga-routines';

import navWatch from 'modules/nav/sagas';
import authWatch from 'modules/auth/sagas';
import meWatch from 'modules/me/sagas';
import tableDasboardWatch from 'modules/dashboard/sagas';
import prescriptionDetailWatch from 'modules/prescription-detail/sagas';
import doctorOverviewWatch from 'modules/doctor-overview/sagas';
import doctorsWatch from 'modules/doctors/sagas';
import doctorDetailWatch from 'modules/doctor-detail/sagas';
import websocketWatcher from 'modules/websocket/sagas';

function* rootSaga() {
  // eslint-disable-next-line no-useless-catch
  try {
    yield all([
      // external
      spawn(websocketWatcher),
      spawn(navWatch),
      call(routinePromiseWatcherSaga),
      // app
      call(authWatch),
      call(meWatch),
      call(tableDasboardWatch),
      call(prescriptionDetailWatch),
      call(doctorOverviewWatch),
      call(doctorsWatch),
      call(doctorDetailWatch),
    ]);
  } catch (error) {
    throw error;
  }
}

export default rootSaga;
