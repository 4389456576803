export const APPROVE_PRESCRIPTION = 'PRESCRIPTION/APPROVE_PRESCRIPTION';
export const CHANGE_DIALOG_STATE = 'PRESCRIPTION/CHANGE_DIALOG_STATE';
export const CHANGE_PRESCRIPTION_STATE =
  'PRESCRIPTION/CHANGE_PRESCRIPTION_STATE';
export const CHANGE_SYNC_STATE = 'PRESCRIPTION/CHANGE_SYNC_STATE';
export const LOAD_DATA = 'PRESCRIPTION/LOAD_DATA';
export const REJECT_PRESCRIPTION = 'PRESCRIPTION/REJECT_PRESCRIPTION';
export const DOWNLOAD_PDF = 'PRESCRIPTION/DOWNLOAD_PDF';
export const BLOCKING_PRESCRIPTION = 'PRESCRIPTION/BLOCKING';
export const UNBLOCKING_PRESCRIPTION = 'PRESCRIPTION/UNBLOCKING';
