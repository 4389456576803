import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  APPROVE_PRESCRIPTION,
  BLOCKING_PRESCRIPTION,
  CHANGE_DIALOG_STATE,
  CHANGE_PRESCRIPTION_STATE,
  LOAD_DATA,
  REJECT_PRESCRIPTION,
  DOWNLOAD_PDF,
  UNBLOCKING_PRESCRIPTION,
  CHANGE_SYNC_STATE,
} from './types';

// Actions
export const changeDialogState = createAction(CHANGE_DIALOG_STATE);
export const changePrescriptionState = createAction(CHANGE_PRESCRIPTION_STATE);
export const changeSyncState = createAction(CHANGE_SYNC_STATE);

// Routines
export const approvePrescription = createRoutine(APPROVE_PRESCRIPTION);
export const rejectPrescription = createRoutine(REJECT_PRESCRIPTION);
export const detailPrescription = createRoutine(LOAD_DATA);
export const pdfPrescription = createRoutine(DOWNLOAD_PDF);
export const blockingPrescription = createRoutine(BLOCKING_PRESCRIPTION);
export const unblockingPrescription = createRoutine(UNBLOCKING_PRESCRIPTION);
