export const LOAD_INFO_DOCTOR = 'DOCTOR_DETAIL/LOAD_INFO_DOCTOR';
export const LOAD_DOCTOR_LICENSES = 'DOCTOR_DETAIL/LOAD_DOCTOR_LICENSES';
export const LOAD_DOCTOR_UNLICENSES_STATES = 'LOAD_DOCTOR_UNLICENSES_STATES';
export const LOAD_DOCTOR_PRODUCTS_PERMITS =
  'DOCTOR_DETAIL/LOAD_DOCTOR_PRODUCTS_PERMITS';

export const LOAD_DOCTOR_PRODUCTS_NOT_PERMITS =
  'DOCTOR_DETAIL/LOAD_DOCTOR_PRODUCTS_NOT_PERMITS';
export const CREATE_DOCTOR = 'DOCTOR_DETAIL/CREATE_DOCTOR';
export const SAVE_DOCTOR = 'DOCTOR_DETAIL/SAVE_INFO_DOCTOR';
export const SAVE_ADD_LICENSE = 'DOCTOR_DETAIL/SAVE_ADD_LICENSE';
export const SAVE_ADD_LICENSE_SUCCESS =
  'DOCTOR_DETAIL/SAVE_ADD_LICENSE_SUCCESS';
export const SAVE_ADD_PRODUCT_PERMIT = 'SAVE_ADD_PRODUCT_PERMIT';
export const REMOVE_PRODUCT_PERMIT = 'REMOVE_PRODUCT_PERMIT';
export const LOAD_LANGUAGES = 'DOCTOR_DETAIL/LOAD_LANGUAGES';
export const LOAD_COUNTRIES = 'DOCTOR_DETAIL/LOAD_COUNTRIES';
export const LOAD_ZONES = 'DOCTOR_DETAIL/LOAD_ZONES';
export const LOAD_STATES = 'DOCTOR_DETAIL/LOAD_STATES';
export const CLEAN_DOCTOR = 'CLEAN_DOCTOR';
export const DELETE_LICENSES_DOCTOR = 'DOCTOR_DETAIL/DELETE_LICENSES';
export const PREVIEW_DOCTOR_DETAIL = 'DOCTOR_DETAIL/PREVIEW_DOCTOR_DETAIL';
export const DELETE_PREVIEW_DOCTOR_DETAIL =
  'DOCTOR_DETAIL/DELETE_PREVIEW_DOCTOR_DETAIL';

export const UPLOAD_DOCTOR_SIGN = 'DOCTOR_DETAIL/UPLOAD_DOCTOR_SIGN';
export const UPDATE_DOCTOR_SIGN = 'DOCTOR_DETAIL/UPDATE_DOCTOR_SIGN';
