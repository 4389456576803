/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFilters } from 'modules/nav/selectors';
import { loadDoctors } from './actions';

function* doctorsSaga() {
  try {
    yield put(loadDoctors.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctors'], paramsURL);
    yield put(loadDoctors.success(body));
  } catch (error) {
    yield put(loadDoctors.failure(error));
  } finally {
    yield put(loadDoctors.fulfill());
  }
}

export default function* doctorsWatch() {
  yield takeLatest(loadDoctors.TRIGGER, doctorsSaga);
}
