/* eslint-disable no-console, func-names */
import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { ConnectedRouter } from 'connected-react-router';
import { ToastContainer } from 'react-toastify';

import Routes from 'routes';
import routesConfig from 'routes/config';
import { store, persistor } from 'store';
import history from 'store/history';

import AbilityBuilder from 'utils/casl/ability';
import { AbilityContext } from 'views/components/casl';

import SdkLoader from 'views/components/sdk-loader';

import secretManager from 'services/secretmanager.service';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import AWS from 'aws-sdk';
import * as dotenv from 'dotenv';

dotenv.config();

const initSentry = async function () {
  try {
    const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
    const SECRET_KEY = process.env.REACT_APP_AWS_SECRET_ACCESS_KEY;
    const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT;
    AWS.config.update({
      accessKeyId: ACCESS_KEY,
      secretAccessKey: SECRET_KEY,
    });
    const secretValue = await secretManager.getSecretValue('SENTRY');
    const decoded = JSON.parse(secretValue);
    console.log('decoded is..', decoded);
    const sentryDsn = decoded[`SENTRY_${ENVIRONMENT.toUpperCase()}_FRONTEND`];
    console.log('sentry dsn..', sentryDsn);

    Sentry.init({
      dsn: sentryDsn,
      integrations: [new BrowserTracing()],
      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  } catch (exception) {
    console.log('exception triggered..');
    console.log(exception);
  }
};

initSentry();

const App = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AbilityContext.Provider value={AbilityBuilder}>
        <SdkLoader>
          <ConnectedRouter history={history}>
            <Routes store={store} routes={routesConfig} />
            <ToastContainer />
          </ConnectedRouter>
        </SdkLoader>
      </AbilityContext.Provider>
    </PersistGate>
  </Provider>
);

export default App;
