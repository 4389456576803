import React from 'react';

const Loading = () => (
  <article className="main-loading--global">
    <div className="spinner-border" role="status" />
    <span>Loading...</span>
  </article>
);

export default Loading;
