import { createAction } from 'redux-actions';
import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {
  CLEAN_DOCTOR,
  CREATE_DOCTOR,
  LOAD_COUNTRIES,
  LOAD_DOCTOR_LICENSES,
  LOAD_DOCTOR_PRODUCTS_NOT_PERMITS,
  LOAD_DOCTOR_UNLICENSES_STATES,
  LOAD_INFO_DOCTOR,
  LOAD_LANGUAGES,
  LOAD_STATES,
  LOAD_ZONES,
  SAVE_DOCTOR,
  LOAD_DOCTOR_PRODUCTS_PERMITS,
  SAVE_ADD_LICENSE,
  SAVE_ADD_LICENSE_SUCCESS,
  SAVE_ADD_PRODUCT_PERMIT,
  REMOVE_PRODUCT_PERMIT,
  DELETE_LICENSES_DOCTOR,
  PREVIEW_DOCTOR_DETAIL,
  DELETE_PREVIEW_DOCTOR_DETAIL,
  UPLOAD_DOCTOR_SIGN,
  UPDATE_DOCTOR_SIGN,
} from './types';

export const cleanDoctor = createRoutine(CLEAN_DOCTOR);
export const loadInfoDoctorDetail = createRoutine(LOAD_INFO_DOCTOR);
export const loadDoctorUnlicensesStates = createRoutine(
  LOAD_DOCTOR_UNLICENSES_STATES
);
export const loadDoctorLicenses = createRoutine(LOAD_DOCTOR_LICENSES);
export const loadDoctorProductsPermits = createRoutine(
  LOAD_DOCTOR_PRODUCTS_PERMITS
);
export const loadDoctorProductsNotPermits = createRoutine(
  LOAD_DOCTOR_PRODUCTS_NOT_PERMITS
);
export const loadLanguages = createRoutine(LOAD_LANGUAGES);
export const loadZones = createRoutine(LOAD_ZONES);
export const loadCountries = createRoutine(LOAD_COUNTRIES);
export const loadStates = createRoutine(LOAD_STATES);

export const createDoctor = createRoutine(CREATE_DOCTOR);
export const bindedCreateDoctor = bindRoutineToReduxForm(createDoctor);

export const saveDoctor = createRoutine(SAVE_DOCTOR);
export const bindedSaveDoctor = bindRoutineToReduxForm(saveDoctor);

export const saveLicense = createRoutine(SAVE_ADD_LICENSE);
export const saveProductPermit = createRoutine(SAVE_ADD_PRODUCT_PERMIT);
export const removeProductPermit = createRoutine(REMOVE_PRODUCT_PERMIT);

export const bindedSaveLicense = bindRoutineToReduxForm(saveLicense);
export const saveLicenseSuccess = createRoutine(SAVE_ADD_LICENSE_SUCCESS);

export const deleteLicenses = createRoutine(DELETE_LICENSES_DOCTOR);
export const previewDoctor = createRoutine(PREVIEW_DOCTOR_DETAIL);
export const deletePreviewDoctor = createRoutine(DELETE_PREVIEW_DOCTOR_DETAIL);
export const uploadDoctorSign = createRoutine(UPLOAD_DOCTOR_SIGN);
export const updateDoctorSignUrl = createAction(UPDATE_DOCTOR_SIGN);
