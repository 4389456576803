/* eslint-disable import/no-unresolved */
/* eslint-disable no-undef */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-useless-catch */
import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFilters } from 'modules/nav/selectors';
import {
  loadPrescriptions,
  loadPrescriptionsTotal,
  loadDoctorList,
  updateData,
} from './actions';

function* loadPrescriptionsSaga() {
  try {
    yield put(loadPrescriptions.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.dashboard, 'getAllPrescriptions'],
      paramsURL
    );
    yield put(loadPrescriptions.success(body));
  } catch (error) {
    yield put(loadPrescriptions.failure(error));
  } finally {
    yield put(loadPrescriptions.fulfill());
  }
}

function* loadPrescriptionsTotalSaga() {
  try {
    yield put(loadPrescriptionsTotal.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.prescriptions, 'countByStatus'],
      paramsURL
    );
    yield put(loadPrescriptionsTotal.success(body));
  } catch (error) {
    yield put(loadPrescriptionsTotal.failure(error));
  } finally {
    yield put(loadPrescriptions.fulfill());
  }
}

function* silentTableDasboardSaga() {
  try {
    yield put(updateData.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.dashboard, 'getAllPrescriptions'],
      paramsURL
    );
    yield put(updateData.success(body));
    yield put(loadPrescriptionsTotal());
  } catch (error) {
    yield put(updateData.failure(error));
  } finally {
    yield put(updateData.fulfill());
  }
}

function* doctorList() {
  try {
    yield put(loadDoctorList.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAllDoctors'], {});
    yield put(loadDoctorList.success(body));
  } catch (error) {
    yield put(loadDoctorList.failure(error));
  } finally {
    yield put(loadDoctorList.fulfill());
  }
}

export default function* tableDasboardWatch() {
  yield takeLatest(loadPrescriptions.TRIGGER, loadPrescriptionsSaga);
  yield takeLatest(loadPrescriptionsTotal.TRIGGER, loadPrescriptionsTotalSaga);
  yield takeLatest(loadDoctorList.TRIGGER, doctorList);
  yield takeLatest(updateData.TRIGGER, silentTableDasboardSaga);
}
