import produce from 'immer';
import {
  approvePrescription,
  detailPrescription,
  rejectPrescription,
} from './actions';
import {
  CHANGE_DIALOG_STATE,
  CHANGE_PRESCRIPTION_STATE,
  CHANGE_SYNC_STATE,
} from './types';

const initialState = {
  loading: false,
  alert: {
    show: false,
    message: '',
    color: 'primary',
  },
  emptyState: false,
  dialogs: {
    accept: false,
    reject: false,
    preview: false,
  },
  prescriptionResult: {
    refills: 0,
    directions: '',
    isSyncReview: false,
    isInvalidDirections: false,
  },
  prescription: {
    id: null,
    createdAt: null,
    signedAt: null,
    status: null,
    isBlocking: null,
    isSyncReview: null,
    hellosignFileUrl: null,
    hellosignSigningUrl: null,
    orderStatus: null,
    questionnaireStatus: null,
    platformOrderId: null,
    orderNumber: null,
    isEhrOrder: null,
    orderSubscriptionType: '',
    variantName: '',
    variantSku: '',
    productName: '',
    patientFullName: '',
    patientGender: null,
    patientBirthDate: null,
    patientEmail: '',
    patientCellphone: '',
    patientState: '',
    patientCity: '',
    patientZipcode: '',
    doctorName: null,
    answers: [],
  },
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case CHANGE_PRESCRIPTION_STATE:
      draft.prescriptionResult = { ...draft.prescriptionResult, ...payload };
      break;
    case CHANGE_SYNC_STATE:
      draft.prescriptionResult = { ...draft.prescriptionResult, ...payload };
      break;
    case CHANGE_DIALOG_STATE:
      draft.dialogs = { ...draft.dialogs, ...payload };
      break;
    case detailPrescription.REQUEST:
      draft.loading = true;
      draft.prescription = initialState.prescription;
      draft.dialogs = initialState.dialogs;
      draft.prescriptionResult = initialState.prescriptionResult;
      draft.pdfDownload = initialState.pdfDownload;
      break;
    case detailPrescription.SUCCESS: {
      if (payload) {
        draft.prescription = payload;
        draft.emptyState = false;
      } else {
        draft.emptyState = true;
      }
      break;
    }
    case detailPrescription.FULFILL:
      draft.loading = false;
      break;
    case approvePrescription.REQUEST:
      draft.loading = true;
      break;
    case approvePrescription.FULFILL:
      draft.loading = false;
      break;
    case rejectPrescription.REQUEST:
      draft.loading = true;
      break;
    case rejectPrescription.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
