/* eslint-disable import/prefer-default-export */
import { createRoutine } from 'redux-saga-routines';
import {
  LOAD_DOCTOR_DATA,
  LOAD_DOCTOR_LICENSES,
  LOAD_DOCTOR_PRODUCTS,
  LOAD_PRESCRIPTIONS,
  LOAD_PRESCRIPTIONS_TOTAL,
} from './types';

export const loadPrescriptionsDoctorOverview =
  createRoutine(LOAD_PRESCRIPTIONS);
export const loadDoctorData = createRoutine(LOAD_DOCTOR_DATA);
export const loadDoctorLicences = createRoutine(LOAD_DOCTOR_LICENSES);
export const loadDoctorProducts = createRoutine(LOAD_DOCTOR_PRODUCTS);
export const loadPrescriptionsTotalOverview = createRoutine(
  LOAD_PRESCRIPTIONS_TOTAL
);
