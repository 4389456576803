/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-console */
import { call, take, takeEvery } from 'redux-saga/effects';
import { eventChannel } from 'redux-saga';
import { put } from 'redux-saga-test-plan/matchers';
import { connectWS } from './actions';

const wsChannel = (socket) =>
  eventChannel((emit) => {
    const handleMessage = (data) => {
      emit(data);
    };

    socket.on('message', handleMessage);

    return () => {
      socket.off('message', handleMessage);
    };
  });

function* connectWSSaga({ payload }) {
  try {
    const channelSession = yield call(wsChannel, payload);
    if (payload) {
      while (true) {
        const actionSession = yield take(channelSession);
        yield put(actionSession);
      }
    }
  } catch (error) {
    console.error(error);
    throw error;
  }
}

export default function* websocketWatch() {
  yield takeEvery(connectWS.toString(), connectWSSaga);
}
