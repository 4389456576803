import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { authIsLoggedIn, getAuthToken } from 'modules/auth/selectors';
import SDK from 'utils/sdk';

const SdkLoader = ({ children }) => {
  const [ready, setReady] = useState(false);
  const token = useSelector(getAuthToken);
  const dispatch = useDispatch();
  const isLoggedIn = useSelector(authIsLoggedIn);
  useEffect(() => {
    async function init() {
      await SDK.init();
      await SDK.initSocket(isLoggedIn, dispatch);
      SDK.setToken(token);
      setReady(true);
    }
    init();
  }, [token, isLoggedIn, dispatch]);

  if (!ready) return null;

  return children;
};

SdkLoader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default SdkLoader;
