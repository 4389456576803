import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import {
  optionsPrescription,
  optionsIconFalse,
  successMessage,
  errorMessage,
} from 'utils/toast-options';
import { toast } from 'react-toastify';

import { getUserRole } from 'modules/me/selectors';
import {
  detailPrescription,
  approvePrescription,
  rejectPrescription,
  changeDialogState,
  pdfPrescription,
  blockingPrescription,
  unblockingPrescription,
} from './actions';

function* getPrescriptionByIdSaga({ payload: { prescriptionId } }) {
  try {
    yield put(detailPrescription.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.prescriptions, 'getPrescription'], {
      prescriptionId,
    });
    yield put(detailPrescription.success(body));
  } catch (error) {
    yield put(detailPrescription.failure(error));
  } finally {
    yield put(detailPrescription.fulfill());
  }
}

function* approvePrescriptionByIdSaga({ payload }) {
  try {
    yield put(approvePrescription.request());
    const api = yield call([SDK, 'getApi']);
    const socket = yield call([SDK, 'getSocket']);
    const { body } = yield call(
      [api.prescriptions, 'approvePrescription'],
      {},
      { requestBody: { ...payload, socketId: socket.id } }
    );

    toast.success(successMessage, optionsPrescription);
    yield put(approvePrescription.success(body));
    yield put(detailPrescription({ prescriptionId: body.prescriptionId }));
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(approvePrescription.failure(error));
  } finally {
    yield put(approvePrescription.fulfill());
    yield put(changeDialogState({ accept: false }));
  }
}

function* rejectPrescriptionByIdSaga({ payload }) {
  try {
    yield put(rejectPrescription.request());
    const api = yield call([SDK, 'getApi']);
    const socket = yield call([SDK, 'getSocket']);
    const { body } = yield call(
      [api.prescriptions, 'rejectPrescription'],
      {},
      { requestBody: { ...payload, socketId: socket.id } }
    );

    yield put(rejectPrescription.success());
    toast.error(errorMessage, optionsPrescription);
    yield put(detailPrescription({ prescriptionId: body.prescriptionId }));
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(rejectPrescription.failure(error));
  } finally {
    yield put(rejectPrescription.fulfill());
    yield put(changeDialogState({ reject: false }));
  }
}

function* getPrescriptionPdfSaga({ payload: { prescriptionId } }) {
  try {
    yield put(pdfPrescription.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.prescriptions, 'getPrescriptionPdf'], {
      prescriptionId,
    });
    const a = window.document.createElement('a');
    a.href = body;
    a.download = 'new_document.pdf';
    a.click();
    a.remove();
    yield put(pdfPrescription.success());
  } catch (error) {
    yield put(pdfPrescription.failure(error));
  } finally {
    yield put(pdfPrescription.fulfill());
  }
}

export function* blockingPrescriptionSaga({
  payload: { prescriptionId, prescriptionStatus },
}) {
  try {
    yield put(blockingPrescription.request());
    const role = yield select(getUserRole);
    if (prescriptionStatus === 'pending' && role.name === 'Doctor') {
      const api = yield call([SDK, 'getApi']);
      const socket = yield call([SDK, 'getSocket']);
      yield call(
        [api.prescriptions, 'blocking'],
        { id: prescriptionId },
        { requestBody: { blocking: true, socketId: socket.id } }
      );
    }
    yield put(blockingPrescription.success());
  } catch (error) {
    yield put(blockingPrescription.failure(error));
    toast.error('Ops! Something was wrong!', optionsIconFalse);
  } finally {
    yield put(blockingPrescription.fulfill());
  }
}

export function* unBlockingPrescriptionSaga({
  payload: { prescriptionId, prescriptionStatus },
}) {
  try {
    yield put(unblockingPrescription.request());
    const role = yield select(getUserRole);
    if (prescriptionStatus === 'pending' && role.name === 'Doctor') {
      const api = yield call([SDK, 'getApi']);
      const socket = yield call([SDK, 'getSocket']);
      yield call(
        [api.prescriptions, 'blocking'],
        { id: prescriptionId },
        { requestBody: { blocking: false, socketId: socket.id } }
      );
    }
    yield put(unblockingPrescription.success());
  } catch (error) {
    yield put(unblockingPrescription.failure(error));
    toast.error('Ops! Something was wrong!', optionsIconFalse);
  } finally {
    yield put(unblockingPrescription.fulfill());
  }
}

export default function* prescriptionDetailWatch() {
  yield takeLatest(detailPrescription.TRIGGER, getPrescriptionByIdSaga);
  yield takeLatest(approvePrescription.TRIGGER, approvePrescriptionByIdSaga);
  yield takeLatest(rejectPrescription.TRIGGER, rejectPrescriptionByIdSaga);
  yield takeLatest(pdfPrescription.TRIGGER, getPrescriptionPdfSaga);
  yield takeLatest(blockingPrescription.TRIGGER, blockingPrescriptionSaga);
  yield takeLatest(unblockingPrescription.TRIGGER, unBlockingPrescriptionSaga);
}
