import produce from 'immer';
import { totalPage } from 'utils/pagination/current-or-total-page-value';
import {
  loadDoctorData,
  loadDoctorLicences,
  loadDoctorProducts,
  loadPrescriptionsDoctorOverview,
  loadPrescriptionsTotalOverview,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  meta: {
    count: 0,
    currentPage: null,
    totalPages: 0,
    hasPreviousPage: null,
    hasNextPage: null,
  },
  counterPrescriptions: 0,
  prescriptions: [],
  prescriptionsTotal: {},
  doctorInformation: {},
  doctorLicences: [],
  doctorProducts: [],
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case loadPrescriptionsDoctorOverview.REQUEST:
      draft.loading = true;
      break;
    case loadPrescriptionsDoctorOverview.SUCCESS:
      draft.prescriptions = payload.prescriptions;
      if (payload.prescriptions.length > 0) {
        draft.meta.currentPage = payload.pagination.currentPage;
        draft.meta.totalPages = totalPage(payload.count);
        draft.meta.hasPreviousPage = draft.meta.currentPage !== 1;
        draft.meta.hasNextPage =
          draft.meta.currentPage !== draft.meta.totalPages;
      }
      break;
    case loadPrescriptionsDoctorOverview.FAILURE:
      draft.error = payload;
      break;
    case loadPrescriptionsDoctorOverview.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorData.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorData.SUCCESS:
      draft.doctorInformation = { ...payload };
      break;
    case loadDoctorData.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorData.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorLicences.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorLicences.SUCCESS:
      draft.doctorLicences = [...payload];
      break;
    case loadDoctorLicences.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorLicences.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorProducts.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorProducts.SUCCESS:
      draft.doctorProducts = payload;
      break;
    case loadDoctorProducts.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorProducts.FULFILL:
      draft.loading = false;
      break;
    case loadPrescriptionsTotalOverview.REQUEST:
      draft.loading = true;
      break;
    case loadPrescriptionsTotalOverview.SUCCESS:
      draft.prescriptionsTotal = payload;
      break;
    case loadPrescriptionsTotalOverview.FAILURE:
      draft.error = payload;
      break;
    case loadPrescriptionsTotalOverview.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
