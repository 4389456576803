import { bindRoutineToReduxForm, createRoutine } from 'redux-saga-routines';
import {
  AUTH_LOGIN,
  GENERATE_TWO_FACTOR,
  VALIDATE_TWO_FACTOR,
  AUTH_LOGOUT,
  RETRIEVE_ME,
  FORGOT_PASSWORD,
  CODE_VALIDATION,
  NEW_PASSWORD,
} from './types';

export const authLogin = createRoutine(AUTH_LOGIN);
export const authLogout = createRoutine(AUTH_LOGOUT);

export const retrieveMe = createRoutine(RETRIEVE_ME);
export const authForgotPassword = createRoutine(FORGOT_PASSWORD);
export const authGenerateTwoFactor = createRoutine(GENERATE_TWO_FACTOR);
export const authValidateTwoFactor = createRoutine(VALIDATE_TWO_FACTOR);
export const authCodeValidation = createRoutine(CODE_VALIDATION);
export const authNewPassword = createRoutine(NEW_PASSWORD);

export const bindedAuthLogin = bindRoutineToReduxForm(authLogin);
export const bindedAuthTwoSteps = bindRoutineToReduxForm(authValidateTwoFactor);
export const bindedAuthForgotPassword =
  bindRoutineToReduxForm(authForgotPassword);

export const bindedAuthNewPassword = bindRoutineToReduxForm(authNewPassword);
