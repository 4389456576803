import produce from 'immer';
import { totalPage } from 'utils/pagination/current-or-total-page-value';
import { loadDoctors } from './actions';

const initialState = {
  loading: false,
  error: null,
  meta: {
    count: 0,
    currentPage: null,
    totalPages: 0,
    hasPreviousPage: null,
    hasNextPage: null,
  },
  counterDoctors: 0,
  doctors: [],
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case loadDoctors.REQUEST:
      draft.loading = true;
      break;
    case loadDoctors.SUCCESS:
      draft.doctors = [...payload.doctors];
      draft.counterDoctors = payload.count;
      if (payload.doctors.length > 0) {
        draft.meta.currentPage = payload.pagination.currentPage;
        draft.meta.totalPages = totalPage(payload.pagination.totalItems);
        draft.meta.hasPreviousPage = draft.meta.currentPage !== 1;
        draft.meta.hasNextPage =
          draft.meta.currentPage !== draft.meta.totalPages;
      }
      break;
    case loadDoctors.FAILURE:
      draft.error = payload;
      break;
    case loadDoctors.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
