/* eslint-disable no-case-declarations */
import produce from 'immer';
import { totalPage } from 'utils/pagination/current-or-total-page-value';
import { useSelector } from 'react-redux';
import { getUserId } from 'modules/me/selectors';

import {
  loadPrescriptions,
  loadPrescriptionsTotal,
  loadDoctorList,
  changeModalData,
  updateData,
  updatedPrescription,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  meta: {
    count: 0,
    currentPage: null,
    totalPages: 0,
    hasPreviousPage: null,
    hasNextPage: null,
  },
  modals: {
    dateRange: false,
    openModalQr: false,
  },
  counterPrescriptions: 0,
  prescriptions: [],
  doctors: [],
  countByStatus: {},
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case loadPrescriptions.REQUEST:
      draft.loading = true;
      break;
    case loadPrescriptions.SUCCESS:
      draft.prescriptions = payload.prescriptions;
      if (payload.prescriptions.length > 0) {
        draft.meta.currentPage = payload.pagination.currentPage;
        draft.meta.totalPages = totalPage(payload.count);
        draft.meta.hasPreviousPage = draft.meta.currentPage !== 1;
        draft.meta.hasNextPage =
          draft.meta.currentPage !== draft.meta.totalPages;
      }
      break;
    case updateData.SUCCESS:
      draft.prescriptions = payload.prescriptions;
      if (payload.prescriptions.length > 0) {
        draft.meta.currentPage = payload.pagination.currentPage;
        draft.meta.totalPages = totalPage(payload.count);
        draft.meta.hasPreviousPage = draft.meta.currentPage !== 1;
        draft.meta.hasNextPage =
          draft.meta.currentPage !== draft.meta.totalPages;
      }
      break;
    case loadPrescriptions.FAILURE:
      draft.error = payload;
      break;
    case updateData.FAILURE:
      draft.error = payload;
      break;
    case loadPrescriptions.FULFILL:
      draft.loading = false;
      break;
    case loadPrescriptionsTotal.REQUEST:
      draft.loading = true;
      break;
    case loadPrescriptionsTotal.SUCCESS:
      draft.countByStatus = { ...payload };
      break;
    case loadPrescriptionsTotal.FAILURE:
      draft.error = payload;
      break;
    case loadPrescriptionsTotal.FULFILL:
      draft.loading = false;
      break;
    case loadDoctorList.REQUEST:
      draft.loading = true;
      break;
    case loadDoctorList.SUCCESS:
      draft.doctors = payload;
      break;
    case loadDoctorList.FAILURE:
      draft.error = payload;
      break;
    case loadDoctorList.FULFILL:
      draft.loading = false;
      break;
    case changeModalData.toString():
      draft.modals = { ...draft.modals, ...payload };
      break;
    case updatedPrescription.toString():
      const currentDoctorId = useSelector(getUserId);

      draft.prescriptions = draft.prescriptions.map((item) => {
        const founded = payload.blockedPrescriptions.find((payloadItem) => {
          // return item.prescriptionId === payloadItem.id;
          // blockedBy
          return (
            item.prescriptionId === payloadItem.id &&
            currentDoctorId !== payloadItem.blockedBy
          );
        });
        return founded
          ? {
              ...item,
              isBlockingPrescription: true,
              blockedBy: founded.blockedBy,
            }
          : {
              ...item,
              isBlockingPrescription: false,
              blockedBy: null,
            };
      });

      break;
    // no default
  }
}, initialState);
