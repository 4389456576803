import SDK from 'utils/sdk';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { getFilters } from 'modules/nav/selectors';
import {
  loadDoctorData,
  loadDoctorLicences,
  loadDoctorProducts,
  loadPrescriptionsDoctorOverview,
  loadPrescriptionsTotalOverview,
} from './actions';

function* doctorOverviewPrescriptionsSaga({ payload: { id } }) {
  try {
    yield put(loadPrescriptionsDoctorOverview.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.dashboard, 'getAllPrescriptions'], {
      doctorId: id,
      ...paramsURL,
    });
    yield put(loadPrescriptionsDoctorOverview.success(body));
  } catch (error) {
    yield put(loadPrescriptionsDoctorOverview.failure(error));
  } finally {
    yield put(loadPrescriptionsDoctorOverview.fulfill());
  }
}

function* doctorDataSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorData.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctor'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorData.success(body));
  } catch (error) {
    yield put(loadDoctorData.failure(error));
  } finally {
    yield put(loadDoctorData.fulfill());
  }
}

function* doctorLicencesSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorLicences.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctorLicences'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorLicences.success(body));
  } catch (error) {
    yield put(loadDoctorLicences.failure(error));
  } finally {
    yield put(loadDoctorLicences.fulfill());
  }
}

function* doctorProductsSaga({ payload: { id } }) {
  try {
    yield put(loadDoctorProducts.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctorProducts'], {
      id,
      ...paramsURL,
    });
    yield put(loadDoctorProducts.success(body));
  } catch (error) {
    yield put(loadDoctorProducts.failure(error));
  } finally {
    yield put(loadDoctorProducts.fulfill());
  }
}

function* doctorOverviewPrescriptionsTotalSaga({ payload: { id } }) {
  try {
    yield put(loadPrescriptionsTotalOverview.request());
    const paramsURL = yield select(getFilters);
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.prescriptions, 'countByStatus'], {
      doctorId: id,
      ...paramsURL,
    });
    yield put(loadPrescriptionsTotalOverview.success(body));
  } catch (error) {
    yield put(loadPrescriptionsTotalOverview.failure(error));
  } finally {
    yield put(loadPrescriptionsTotalOverview.fulfill());
  }
}

export default function* doctorOverviewWatch() {
  yield takeLatest(
    loadPrescriptionsDoctorOverview.TRIGGER,
    doctorOverviewPrescriptionsSaga
  );
  yield takeLatest(loadDoctorData.TRIGGER, doctorDataSaga);
  yield takeLatest(loadDoctorLicences.TRIGGER, doctorLicencesSaga);
  yield takeLatest(loadDoctorProducts.TRIGGER, doctorProductsSaga);
  yield takeLatest(
    loadPrescriptionsTotalOverview.TRIGGER,
    doctorOverviewPrescriptionsTotalSaga
  );
}
