/* eslint-disable no-const-assign */
import { Ability, AbilityBuilder } from '@casl/ability';

export const ability = new Ability();

export default (action, subject) => {
  return ability.can(action, subject);
};

export const defineRulesFor = (permissions) => {
  const { can, rules } = new AbilityBuilder();

  if (permissions) {
    permissions.forEach((permission) => {
      can(permission.action, permission.subject);
    });
  }

  return rules;
};
