import { createRoutine } from 'redux-saga-routines';
import { createAction } from 'redux-actions';

import {
  LOAD_DATA_PRESCRIPTIONS,
  FILTER_DATA,
  /*   TOTAL_ORDERS, */
  TOTAL_PRESCRIPTIONS,
  LOAD_DOCTOR_LIST,
  CHANGE_MODAL_DATA,
  UPDATED_PRESCRIPTION,
  UPDATE_DATA,
} from './types';

export const changeModalData = createAction(CHANGE_MODAL_DATA);
export const updatedPrescription = createAction(UPDATED_PRESCRIPTION);

export const updateData = createRoutine(UPDATE_DATA);
export const filter = createRoutine(FILTER_DATA);
export const loadPrescriptions = createRoutine(LOAD_DATA_PRESCRIPTIONS);
export const loadPrescriptionsTotal = createRoutine(TOTAL_PRESCRIPTIONS);
export const loadDoctorList = createRoutine(LOAD_DOCTOR_LIST);
