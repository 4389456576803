import SDK from 'utils/sdk';
import { call, put, takeLatest } from 'redux-saga/effects';
import { toast } from 'react-toastify';

import { optionsComun, optionsIconFalse } from 'utils/toast-options';

import {
  cleanDoctor,
  createDoctor,
  loadCountries,
  loadDoctorLicenses,
  loadDoctorProductsNotPermits,
  loadDoctorUnlicensesStates,
  loadInfoDoctorDetail,
  loadLanguages,
  loadStates,
  loadZones,
  loadDoctorProductsPermits,
  saveLicense,
  saveLicenseSuccess,
  saveProductPermit,
  removeProductPermit,
  saveDoctor,
  deleteLicenses,
  previewDoctor,
  deletePreviewDoctor,
  uploadDoctorSign,
} from './actions';

function* addDoctor({ payload: { values: requestBody } }) {
  try {
    yield put(createDoctor.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.doctors, 'addDoctor'],
      {},
      {
        requestBody: {
          ...requestBody,
          ...requestBody.user,
        },
      }
    );
    yield put(createDoctor.success(body));
    toast.success('Doctor Added', optionsComun);
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(createDoctor.failure(error));
  } finally {
    yield put(createDoctor.fulfill());
  }
}

function* clearDoctor() {
  try {
    yield put(cleanDoctor.request());
    yield put(cleanDoctor.success());
  } catch (error) {
    yield put(cleanDoctor.failure(error));
  } finally {
    yield put(cleanDoctor.fulfill());
  }
}
function* previewDoctorSaga() {
  yield put(previewDoctor.success());
}
function* deletePreviewDoctorSaga() {
  yield put(deletePreviewDoctor.success());
}

function* doctorInfoSaga({ payload: { id } }) {
  try {
    yield put(loadInfoDoctorDetail.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctor'], { id });
    yield put(loadInfoDoctorDetail.success(body));
  } catch (error) {
    yield put(loadInfoDoctorDetail.failure(error));
  } finally {
    yield put(loadInfoDoctorDetail.fulfill());
  }
}

function* doctorUnlicensesStates({ payload: { id } }) {
  try {
    yield put(loadDoctorUnlicensesStates.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctorUnlicensedStates'], {
      id,
    });
    yield put(loadDoctorUnlicensesStates.success(body));
  } catch (error) {
    yield put(loadDoctorUnlicensesStates.failure(error));
  } finally {
    yield put(loadDoctorUnlicensesStates.fulfill());
  }
}

function* doctorLicenses({ payload: { id } }) {
  try {
    yield put(loadDoctorLicenses.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctorLicences'], { id });
    yield put(loadDoctorLicenses.success(body));
  } catch (error) {
    yield put(loadDoctorLicenses.failure(error));
  } finally {
    yield put(loadDoctorLicenses.fulfill());
  }
}

function* doctorProductsNotPermits({ payload: { id } }) {
  try {
    yield put(loadDoctorProductsNotPermits.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.doctors, 'getDoctorProductsNotPrescriber'],
      {
        id,
      }
    );
    yield put(loadDoctorProductsNotPermits.success(body));
  } catch (error) {
    yield put(loadDoctorProductsNotPermits.failure(error));
  } finally {
    yield put(loadDoctorProductsNotPermits.fulfill());
  }
}

function* doctorProductsPermits({ payload: { id } }) {
  try {
    yield put(loadDoctorProductsPermits.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.doctors, 'getDoctorProducts'], {
      id,
    });
    yield put(loadDoctorProductsPermits.success(body));
  } catch (error) {
    yield put(loadDoctorProductsPermits.failure(error));
  } finally {
    yield put(loadDoctorProductsPermits.fulfill());
  }
}

function* languages() {
  try {
    yield put(loadLanguages.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.languages, 'getAllLanguages']);
    yield put(loadLanguages.success(body));
  } catch (error) {
    yield put(loadLanguages.failure(error));
  } finally {
    yield put(loadLanguages.fulfill());
  }
}

function* zones() {
  try {
    yield put(loadZones.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.zones, 'getAllZones']);
    yield put(loadZones.success(body));
  } catch (error) {
    yield put(loadZones.failure(error));
  } finally {
    yield put(loadZones.fulfill());
  }
}

function* countries() {
  try {
    yield put(loadCountries.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.countries, 'getAllCountries']);
    yield put(loadCountries.success(body));
  } catch (error) {
    yield put(loadCountries.failure(error));
  } finally {
    yield put(loadCountries.fulfill());
  }
}

function* states() {
  try {
    yield put(loadStates.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call([api.states, 'getAllStates']);
    yield put(loadStates.success(body));
  } catch (error) {
    yield put(loadStates.failure(error));
  } finally {
    yield put(loadStates.fulfill());
  }
}

function* saveDoctorSaga({ payload: { values: requestBody } }) {
  try {
    yield put(saveDoctor.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.doctors, 'putDoctor'],
      { id: requestBody.id },
      {
        requestBody: {
          ...requestBody,
          ...requestBody.user,
          signUrlControl: undefined,
          twoFactorAuthEnabled: undefined,
        },
      }
    );
    yield put(saveDoctor.success(body));
    toast.success('Doctor Saved', optionsComun);
  } catch (error) {
    toast.error('Ops! Something was wrong!', optionsIconFalse);
    yield put(saveDoctor.failure(error));
  } finally {
    yield put(saveDoctor.fulfill());
  }
}

function* saveLicenseSaga({ payload: { values } }) {
  try {
    yield put(saveLicense.request());
    const api = yield call([SDK, 'getApi']);
    yield call(
      [api.doctors, 'addLicenseByState'],
      { id: values.doctorId },
      { requestBody: values }
    );
    yield put(saveLicense.success({ id: values.doctorId }));
  } catch (error) {
    yield put(saveLicense.fulfill());
  }
}

function* saveLicenseSuccessSaga(payload) {
  try {
    yield put(saveLicenseSuccess.request());
    yield call(doctorUnlicensesStates, payload);
    yield call(doctorLicenses, payload);
    yield put(saveLicenseSuccess.success());
    toast.success('New license saved', optionsComun);
  } catch (error) {
    yield put(saveLicenseSuccess.fulfill());
  }
}

function* saveProductPermitSaga({ payload }) {
  try {
    yield put(saveProductPermit.request());
    const api = yield call([SDK, 'getApi']);
    const { body } = yield call(
      [api.doctors, 'postDoctorProduct'],
      { id: payload.doctorId },
      { requestBody: payload }
    );
    yield put(saveProductPermit.success(body));
    toast.success('Product permit removed', optionsComun);
    yield put(loadDoctorProductsPermits.trigger({ id: payload.doctorId }));
    yield put(loadDoctorProductsNotPermits.trigger({ id: payload.doctorId }));
  } catch (error) {
    yield put(saveProductPermit.failure(error));
  } finally {
    yield put(saveProductPermit.fulfill());
  }
}

function* removeProductPermitSaga({ payload }) {
  try {
    yield put(removeProductPermit.request());
    const api = yield call([SDK, 'getApi']);
    yield call([api.doctors, 'deleteDoctorProduct'], {
      id: payload.doctorId,
      productId: payload.productId,
    });
    yield put(removeProductPermit.success({ id: payload.doctorId }));
    toast.success('Product permit added', optionsComun);
    yield put(loadDoctorProductsPermits.trigger({ id: payload.doctorId }));
    yield put(loadDoctorProductsNotPermits.trigger({ id: payload.doctorId }));
  } catch (error) {
    yield put(removeProductPermit.failure(error));
  } finally {
    yield put(removeProductPermit.fulfill());
  }
}

function* removeLicenceSaga({ payload }) {
  try {
    yield put(deleteLicenses.request());
    const api = yield call([SDK, 'getApi']);
    yield call([api.doctors, 'deleteDoctorLicence'], {
      id: payload.doctorId,
      licenceId: payload.licenceId,
    });
    yield put(deleteLicenses.success({ id: payload.doctorId }));
    toast.success('License removed', optionsComun);
    yield put(loadDoctorLicenses.trigger({ id: payload.doctorId }));
  } catch (error) {
    yield put(deleteLicenses.failure(error));
  } finally {
    yield put(deleteLicenses.fulfill());
  }
}

export function* uploadDoctorSignFile({ payload }) {
  try {
    const form = new FormData();
    form.append('file', payload.file);

    yield put(uploadDoctorSign.request());
    const api = yield call([SDK, 'getApi']);
    yield call(
      [api.doctors, 'uploadDoctorSign'],
      { id: payload.doctorId },
      { requestBody: form }
    );
    toast.success('File Uploaded', optionsComun);
    yield put(uploadDoctorSign.success());
  } catch (error) {
    yield put(uploadDoctorSign.failure());
  } finally {
    yield put(uploadDoctorSign.fulfill());
  }
}

export default function* doctorDetailWatch() {
  yield takeLatest(cleanDoctor.TRIGGER, clearDoctor);
  yield takeLatest(saveDoctor.TRIGGER, saveDoctorSaga);
  yield takeLatest(createDoctor.TRIGGER, addDoctor);
  yield takeLatest(loadInfoDoctorDetail.TRIGGER, doctorInfoSaga);
  yield takeLatest(loadDoctorUnlicensesStates.TRIGGER, doctorUnlicensesStates);
  yield takeLatest(loadDoctorLicenses.TRIGGER, doctorLicenses);
  yield takeLatest(loadDoctorProductsPermits.TRIGGER, doctorProductsPermits);
  yield takeLatest(
    loadDoctorProductsNotPermits.TRIGGER,
    doctorProductsNotPermits
  );
  yield takeLatest(previewDoctor.TRIGGER, previewDoctorSaga);
  yield takeLatest(deletePreviewDoctor.TRIGGER, deletePreviewDoctorSaga);
  yield takeLatest(loadLanguages.TRIGGER, languages);
  yield takeLatest(loadZones.TRIGGER, zones);
  yield takeLatest(loadCountries.TRIGGER, countries);
  yield takeLatest(loadStates.TRIGGER, states);
  yield takeLatest(saveLicense.TRIGGER, saveLicenseSaga);
  yield takeLatest(saveLicenseSuccess.TRIGGER, saveLicenseSuccessSaga);
  yield takeLatest(saveProductPermit.TRIGGER, saveProductPermitSaga);
  yield takeLatest(removeProductPermit.TRIGGER, removeProductPermitSaga);
  yield takeLatest(deleteLicenses.TRIGGER, removeLicenceSaga);
  yield takeLatest(uploadDoctorSign.TRIGGER, uploadDoctorSignFile);
}
