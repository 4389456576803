import produce from 'immer';
import {
  authLogin,
  authGenerateTwoFactor,
  authValidateTwoFactor,
  authLogout,
  authForgotPassword,
  authCodeValidation,
  authNewPassword,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  data: null,
  isLoggedIn: false,
  authGenerate: null,
  authValidate: null,
  emailForgotPassword: null,
  codeValidation: null,
};

export default produce((draft, { type, payload }) => {
  switch (type) {
    case authLogin.REQUEST:
      draft.loading = true;
      break;
    case authLogout.REQUEST:
      draft.loading = true;
      break;
    case authLogin.SUCCESS:
      draft.data = payload;
      draft.isLoggedIn = false;
      draft.error = null;
      break;
    case authLogout.SUCCESS:
      draft.token = null;
      draft.isLoggedIn = false;
      break;
    case authLogin.FAILURE:
      draft.error = payload;
      break;
    case authLogin.FULFILL:
      draft.loading = false;
      break;
    case authGenerateTwoFactor.REQUEST:
      draft.loading = true;
      break;
    case authGenerateTwoFactor.SUCCESS:
      draft.authGenerate = payload;
      break;
    case authGenerateTwoFactor.FAILURE:
      draft.error = payload;
      break;
    case authGenerateTwoFactor.FULFILL:
      draft.loading = false;
      break;
    case authValidateTwoFactor.REQUEST:
      draft.loading = true;
      break;
    case authValidateTwoFactor.SUCCESS:
      draft.data = payload;
      draft.isLoggedIn = true;
      break;
    case authValidateTwoFactor.FAILURE:
      draft.error = payload;
      break;
    case authValidateTwoFactor.FULFILL:
      draft.loading = false;
      break;
    case authForgotPassword.REQUEST:
      draft.loading = true;
      break;
    case authForgotPassword.SUCCESS:
      draft.emailForgotPassword = payload;
      break;
    case authForgotPassword.FAILURE:
      draft.error = payload;
      break;
    case authForgotPassword.FULFILL:
      draft.loading = false;
      break;
    case authCodeValidation.REQUEST:
      draft.loading = true;
      break;
    case authCodeValidation.SUCCESS:
      draft.codeValidation = payload;
      break;
    case authCodeValidation.FAILURE:
      draft.error = payload;
      break;
    case authCodeValidation.FULFILL:
      draft.loading = false;
      break;
    case authNewPassword.REQUEST:
      draft.loading = true;
      break;
    case authNewPassword.SUCCESS:
      draft.codeValidation = null;
      draft.error = null;
      break;
    case authNewPassword.FAILURE:
      draft.error = payload;
      break;
    case authNewPassword.FULFILL:
      draft.loading = false;
      break;
    // no default
  }
}, initialState);
