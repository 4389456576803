/* eslint-disable no-console, func-names */
import AWS from 'aws-sdk';

export default {
  getSecretValue(secretName) {
    const region = 'us-east-1';

    // Create a Secrets Manager client
    const client = new AWS.SecretsManager({
      region,
    });

    return new Promise((resolve) => {
      client.getSecretValue({ SecretId: secretName }, function (err, data) {
        console.log('err is..', err);
        if (err) {
          if (err.code === 'DecryptionFailureException')
            // Secrets Manager can't decrypt the protected secret text using the provided KMS key.
            // Deal with the exception here, and/or rethrow at your discretion.
            throw err;
          else if (err.code === 'InternalServiceErrorException')
            // An error occurred on the server side.
            // Deal with the exception here, and/or rethrow at your discretion.
            throw err;
          else if (err.code === 'InvalidParameterException')
            // You provided an invalid value for a parameter.
            // Deal with the exception here, and/or rethrow at your discretion.
            throw err;
          else if (err.code === 'InvalidRequestException')
            // You provided a parameter value that is not valid for the current state of the resource.
            // Deal with the exception here, and/or rethrow at your discretion.
            throw err;
          else if (err.code === 'ResourceNotFoundException')
            // We can't find the resource that you asked for.
            // Deal with the exception here, and/or rethrow at your discretion.
            throw err;
        } else {
          // Decrypts secret using the associated KMS CMK.
          // Depending on whether the secret is a string or binary, one of these fields will be populated.
          const secret = data.SecretString;
          resolve(secret);
        }
      });
    });
  },
};
