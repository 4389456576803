import { createSelector } from 'reselect';

export const getAuth = (state) => state.auth;
export const getUser = (state) => state.auth.data;
export const authIsLoggedIn = createSelector(
  getAuth,
  (auth) => auth.isLoggedIn
);
export const authGenerate = createSelector(
  getAuth,
  (auth) => auth.authGenerate
);
export const authTokenValidate = createSelector(
  getAuth,
  (auth) => auth?.authValidate?.accessToken
);
export const getAuthToken = createSelector(
  getAuth,
  (auth) => auth?.data?.accessToken
);
export const getAuthEmailReset = createSelector(
  getAuth,
  (auth) => auth.emailForgotPassword
);

export const getCodeValidation = createSelector(
  getAuth,
  (auth) => auth.codeValidation
);
